import React, {useState, useContext} from 'react';
import styles from '../styles/login.module.scss';
import axios from 'axios';

import { UserContext } from '../Contexts/UserContext';

import logo from '../files/logo.png';
import { loginEvent } from '../Contexts/events';

export default function Login() {

  const {authApiUrl, bearerToken, siteId} = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");

  const login = ()=>{
    setLoading(true);

    const data = {
      uId: `${siteId}`,
      msisdn: phone
    };
    const config = {
      headers: {
        'Accept': 'text/plain',
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      }
    };

    axios.post(authApiUrl, data, config)
    .then(response => {
      setLoading(false);
      console.log(response.data);
      if(response.data?.isSuccess){
        sessionStorage.setItem('tokenId', response.data.tokenId);
        loginEvent.emit('loggedIn', true);
      }
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
    
    // setTimeout(() => {
    //   loginEvent.emit('loggedIn', true);
    // }, 1500);
  }

  if(loading){
    return(
      <div className={styles.container} >
        <div className={styles.loadingWrapper} >
          <div className='loading' >
            <div className='box' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container} >
      <div className={styles.login} >
        <div className={styles.logoWrapper} >
          <img src={logo} style={{height: '100%', width: '100%', objectFit: "contain"}} />
        </div>
        <div className={styles.inputWrapper} >
          <label className={styles.label} >Phone number</label>
          <input
            className={styles.input}
            placeholder='Phone number'
            value={phone}
            onChange={(event)=> setPhone(event.target.value) }
          />
        </div>
        <button className={styles.loginBtn} onClick={()=> login() } >
          Login
        </button>
      </div>
    </div>
  )
}
