import React, { useState, useEffect } from 'react';
import styles from '../styles/games.module.scss';
import { FiChevronLeft } from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const categories = {
  0: "Sport",
  1: "Arcade",
  2: "Action",
  3: "Puzzles",
  4: "Entertainment",
  5:"Fun"
}

export default function Games() {

  let { cat } = useParams();
  const navigate = useNavigate();
  const [games, setGames] = useState([]);

  useEffect(() => {
    fetch('/games.json')
      .then(response => response.json())
      .then(data =>{
        setGames(data[cat]);
      })
      .catch((err)=>{
        console.log(err)
      })
  }, []);
  
  return (
    <div className={styles.container} >
      <div className={styles.header} onClick={() => navigate(-1)}  >
        <FiChevronLeft size={32} color='#fff' className={styles.backBtn} />
        <p>{categories[cat]}</p>
      </div>
      <div className={styles.gamesWrapper} >
        { games &&
          games.map(it=>(
            <div key={it.id} className={styles.game} >
              <Link to={"/game/"} state={{gameUrl: it.gameUrl}} className={styles.imgWrapper} >
                <img src={it.imgurl} />
              </Link>
              <p>{it.name}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}
