import React from 'react';
import styles from "../styles/home.module.scss";
import { Link } from 'react-router-dom';

import sportImg from '../files/sport.png';
import arcadeImg from '../files/arcade.png';
import actionImg from '../files/action.png';
import puzzlesImg from '../files/puzzles.png';
import entertainmentImg from '../files/entertainment.png';
import funImg from '../files/fun.png';

const categories = [
  {id: 0, en_title: "Sport", image: sportImg},
  {id: 1, en_title: "Arcade", image: arcadeImg},
  {id: 2, en_title: "Action", image: actionImg},
  {id: 3, en_title: "Puzzles", image: puzzlesImg},
  {id: 4, en_title: "Entertainment", image: entertainmentImg},
  {id: 5, en_title: "Fun", image: funImg},
]

export default function Home() {
  return (
    <div className={styles.homeContainer} >
      {/* <iframe src="/games/egggo/index.html" title="My Game" style={{width: '100vw', height: '90vh'}} ></iframe> */}
      <div className={styles.categoriesWrapper} >
        {
          categories.map(it=>
            <Link to={"/games/"+it.id} key={it.id} className={styles.category} >
              <div className={styles.categoryImg} >
                <img src={it.image} />
              </div>
              <p>{it.en_title}</p>
            </Link>
          )
        }
      </div>
    </div>
  )
}
