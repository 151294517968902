import React from 'react';
import styles from '../styles/game.module.scss';
import { useLocation } from 'react-router-dom';

export default function Game() {

  const location = useLocation();
  const { gameUrl } = location.state || "";

  return (
    <div className={styles.gameContainer} >
      <iframe src={gameUrl} title="My Game" style={{width: '100vw', height: '100vh'}} ></iframe>
    </div>
  )
}
