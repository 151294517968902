import React, {useState, useEffect, useContext} from 'react';
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";
import styles from '../styles/root.module.scss';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { UserContext } from '../Contexts/UserContext';
import { loginEvent } from '../Contexts/events';
// import logo from '../files/logo_black.png';
import logo from '../files/logo.png';

const categories = [
  {id: 0, en_title: "Sport"},
  {id: 1, en_title: "Arcade"},
  {id: 2, en_title: "Action"},
  {id: 3, en_title: "Puzzles"},
  {id: 4, en_title: "Entertainment"},
  {id: 5, en_title: "Fun"},
]

export default function MobileSideBar({close}) {

  const { t, i18n } = useTranslation();
  const {apiUrl, siteId} = useContext(UserContext);
  let location = useLocation();
  const [currTab, setCurrTab] = useState("/");
  
  const logout = ()=>{
    sessionStorage.removeItem("tokenId");
    loginEvent.emit('loggedIn', false);
  }

  useEffect(()=>{
    console.log(location.pathname);
    setCurrTab(location.pathname);
  },[location]);

  return (
    <div className={styles.mobileSideBarContainer} >
      <div className={styles.fixedHeader} >
        <img src={logo} alt="game-zone" />
        <FiX size={28} color="#888" onClick={()=> close()} />
      </div>
      <div className={styles.content} >
        <ul>
          <li>
            <Link to="/" onClick={()=> close()} className={cx({[styles.active]:currTab === '/'})}>
              <span>{t("navBar.home")}</span>
            </Link>
          </li>
          <li className={styles.categories} >
            <ul>
              <li>
                <span className={styles.categoriesTitle} >{ i18n.language === 'en' ? "categories" : "فئات"}</span>
              </li>
              {
                categories.length > 0 && categories.map((it, index)=>
                  <li>
                    <Link onClick={()=> close()} to={"/games/"+it.id} className={cx({[styles.active]:decodeURIComponent(currTab) === `/games/${it.id}` })}>
                      <span>{i18n.language === "en" ? it.en_title : it.ar_title}</span>
                    </Link>
                  </li>
                )
              }
              {/* <li>
                <Link onClick={()=> close()} to={t('home.categories.beauty')} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${t('home.categories.beauty')}` })}>
                  <span>{t('home.categories.beauty')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={()=> close()} to={t('home.categories.career')} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${t('home.categories.career')}` })}>
                  <span>{t('home.categories.career')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={()=> close()} to={t('home.categories.pregnant')} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${t('home.categories.pregnant')}` })}>
                  <span>{t('home.categories.pregnant')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={()=> close()} to={t('home.categories.personality')} className={cx({[styles.active]:decodeURIComponent(currTab) === `/${t('home.categories.personality')}` })}>
                  <span>{t('home.categories.personality')}</span>
                </Link>
              </li> */}
            </ul>
          </li>
          {/* <li>
            <Link onClick={()=> close()} className={cx({[styles.active]:currTab === '/about'})}>
              <span>{t("navBar.about")}</span>
            </Link>
          </li> */}
          {/* <li>
            <Link onClick={()=> close()} to="/categories" className={cx({[styles.active]:currTab === '/more'})}>
              <span>{t("navBar.more")}</span>
            </Link>
          </li> */}
          <li>
            <Link onClick={()=> logout()} to="/">
              <span>{t("navBar.logout")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}